@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Sora:wght@700&display=swap');
$global-font-family: 'Inter', sans-serif;
$global-font-weight: 400 !default;
$global-font-color: #404040 !default;
$header-title-font-family: 'Sora', serif;

h2,
h1,
h3 {
    font-family: 'Sora', sans-serif;
}